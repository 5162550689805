export const useDefaultSeoTags = (): any => {
  const config = useRuntimeConfig();
  return {
    title:
      'ServiceMe - znajdź serwis i napraw urządzenie: smartfon, tablet, laptop, konsola',
    ogTitle:
      'ServiceMe - znajdź serwis i napraw urządzenie: smartfon, tablet, laptop, konsola',
    twitterTitle:
      'ServiceMe - znajdź serwis i napraw urządzenie: smartfon, tablet, laptop, konsola',

    ogSiteName: 'ServiceMe',
    ogType: 'website',
    robots: 'index, follow',

    ogImage: `${config.public.baseUrl}/meta-img.png`,
    twitterImage: `${config.public.baseUrl}/meta-img.png`,
    twitterCard: 'summary_large_image',

    twitterDescription:
      'Zbity wyświetlacz, zalanie urządzenia, potrzebna wymiana baterii, głośna praca, a może inny problem? Wskaż uszkodzenie i porównaj oferty naprawy telefonu, tabletu, komputera bądź konsoli',
    ogDescription:
      'Zbity wyświetlacz, zalanie urządzenia, potrzebna wymiana baterii, głośna praca, a może inny problem? Wskaż uszkodzenie i porównaj oferty naprawy telefonu, tabletu, komputera bądź konsoli',
    description:
      'Zbity wyświetlacz, zalanie urządzenia, potrzebna wymiana baterii, głośna praca, a może inny problem? Wskaż uszkodzenie i porównaj oferty naprawy telefonu, tabletu, komputera bądź konsoli',
  };
};
